import * as Sentry from "@sentry/nuxt";
const sentryEnv = useRuntimeConfig().public.sentryEnv;
Sentry.init({
  enabled: !!sentryEnv,
  dsn: "https://d5d2d285d8f4c9f62246ed8a208e64e9@o4507724773654528.ingest.de.sentry.io/4508087261790289",
  environment: sentryEnv,
  integrations: [
    Sentry.captureConsoleIntegration(),
    Sentry.httpClientIntegration({
      failedRequestStatusCodes: [[400, 599]],
    }),
    Sentry.replayIntegration({
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
  attachStacktrace: true,
  autoSessionTracking: true,
  enableTracing: true,
  replaysOnErrorSampleRate: 1.0,
  tracesSampleRate: 1.0,
});
